import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import _ from 'lodash';
import { Menu, Dropdown as AntDropdown } from 'antd';

import useMediaQuery from '@src/hooks/useMediaQuery';
import { ArrowDownIcon } from '@src/components/common/icon';

const { SubMenu, Item: MenuItem } = Menu;

interface IMenuItem {
	id: string;
	text: string;
	isReset?: boolean;
	subMenu?: IMenuItem[];
}

const GlobalStyle = createGlobalStyle`
  .teaches-sub-menu {
		z-index: 9999;
	}
	.ant-dropdown {
		z-index: 9999;
	}
	.ant-dropdown-menu-item {
		width: 100% !important;
	}
`;

const StyledAntDropdown = styled(AntDropdown)`
	& .ant-dropdown-menu {
		padding: 0px !important;
	}
`;

const StyledAntMenu = styled(Menu)`

	&.ant-dropdown-menu {
		border: 1px solid #DDDDDD !important;
		padding: 0px !important;
		border-radius: 4px;
	}
`;

const StyledAntMenuItem = styled(MenuItem)`
	color: #222222;
	width: 198px;
	max-height: 51px;
	white-space: break-spaces;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;

	& .ant-dropdown-menu-title-content {
		font-size: 16px;
	}
	&.ant-dropdown-menu-item-active {
		background-color: ${props => props.hoverColor || '#EBEBED'} !important;
	}
	&.ant-dropdown-menu-item-selected {
		color: #222222;
		background: #FFFFFF;
		font-size: 14px;
	}
	&.reset-menu-item {
		font-weight: 300;
	}
	@media ${props => props.theme.device.mobile} {
		width: 160px;
	}
`;

const StyledAntSubMenu = styled(SubMenu)`
	& .ant-dropdown-menu-submenu-title {
		color: #222222;
		font-size: 16px;
		width: 198px;
		max-height: 51px;
		white-space: break-spaces;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	& .ant-dropdown-menu-submenu-title:hover {
		background-color: ${props => props.hoverColor || 'rgba(48, 145, 253, 0.15)'} !important;
	}
	& .ant-dropdown-menu-submenu-expand-icon {
		top: 3px;
	}
`;

const Content = styled.div`
	position: relative;
	margin-right: 12px;
`;

const StyleSelected = styled.div`
	display: inline-block;
	background: ${(props: { $isDisabled?: boolean }) => (props.$isDisabled ? '#F5F5F7' : '#FFFFFF')};
	border: 1px solid #DDDDDD;
	box-sizing: border-box;
	border-radius: 4px;
	padding: 8px 12px;
	font-weight: 300;
	font-size: 14px;
	color: #676767;
	width: 104px;
	height: 37px;
	:hover {
		border: 1px solid #222222;
		border-color: ${(props: { $isDisabled?: boolean }) => (props.$isDisabled ? '#DDDDDD' : '#222222')};
	}
	
	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			width: 82px;
			font-size: 12px;
		}
`;

interface ITextContent {
	$isRestItem?: boolean;
	$isPlaceholder?: boolean
}
const TextContent = styled.div`
	font-weight: ${(props: ITextContent) => (props.$isRestItem || props.$isPlaceholder ? 300 : 'normal')} !important;
	color: ${(props: ITextContent) => (props.$isPlaceholder ? '#676767' : '#222222')} !important;
	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			display : inline-block;
			overflow : hidden;
			text-overflow : ellipsis;
			white-space : nowrap;
		}
`;

const IconContent = styled.div`
	position: absolute;
	top: 5px;
	right: 8px;
	svg {
		transition: transform 0.3s ease-in-out;
		${(props: { isOpenDropdown: boolean }) => props.isOpenDropdown && `
			transform: rotate(180deg);
		`}
	}
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)`
	width: 24px;
	height: 24px;
	path {
		fill: #B9B9B9;
	}
`;

export interface IDropdownRef {
	setSelected: ({ id, text }) => void;
}
interface IDropdown {
	defaultId: string;
	defaultText: string;
	isPlaceholder?: boolean;
	menu: {
		id: string;
		text: string;
		isReset?: boolean;
		subMenu?: {
			id: string;
			text: string;
		}[]
	}[];
	component?: ({ selectText, onTrigger }) => JSX.Element;
	onSelect?: ({ id, text, isReset, isSubMenu }) => void;
	className?: string;
	disabled?: boolean;
	subMenuIncludeParent?: boolean;
	popupContainerKey?: string;
	triggerSubMenuAction?: 'hover' | 'click';
	onDropdownClick?: () => void;
	hoverColor?: string;
}

// eslint-disable-next-line react/display-name
const Dropdown = forwardRef((props: IDropdown, ref): JSX.Element => {
	const {
		defaultId,
		defaultText,
		component,
		menu,
		onSelect,
		className,
		disabled,
		subMenuIncludeParent,
		popupContainerKey,
		isPlaceholder = false,
		onDropdownClick,
		hoverColor,
	} = props;
	let { triggerSubMenuAction = 'hover' } = props;
	const [selected, setSelected] = useState({ id: defaultId, text: defaultText, isReset: false });
	const [onTrigger, setOnTrigger] = useState(false);
	const { mediaMatch: { isMobile } } = useMediaQuery();
	if (isMobile) triggerSubMenuAction = 'click';

	useImperativeHandle(ref, () => ({
		setSelected,
	}));

	useEffect(() => {
		const isReset = _.find(menu, { id: defaultId })?.isReset;
		setSelected({ id: defaultId, text: defaultText, isReset });
	}, [defaultText, defaultId]);

	const renderMenuItem = (
		{ id, text, subMenu, isReset }: IMenuItem,
	) => {
		const isSubMenu = subMenu && subMenu.length > 0;
		const onClick = () => {
			if (onSelect) {
				onSelect({ id, text, isReset, isSubMenu });
			} else {
				setSelected({ id, text, isReset });
			}
			setOnTrigger(false);
		};
		if (isSubMenu) {
			if (subMenuIncludeParent) {
				// eslint-disable-next-line no-param-reassign
				subMenu = [{ id, text: `所有${text}` }, ...subMenu];
			}
			return (
				<StyledAntSubMenu
					key={id}
					title={text}
					onTitleClick={() => {
						if (!isMobile) onClick();
					}}
					popupClassName="teaches-sub-menu"
					icon={null}
					hoverColor={hoverColor}
				>
					{
						subMenu.map((item: IMenuItem) => renderMenuItem(item))
					}
				</StyledAntSubMenu>
			);
		} else {
			return (
				<StyledAntMenuItem
					key={id}
					id={id}
					className={isReset && 'reset-menu-item'}
					onClick={onClick}
					hoverColor={hoverColor}
				>
					{text}
				</StyledAntMenuItem>
			);
		}
	};

	const renderMenu = (menuList: IMenuItem[]) => {
		if (!onTrigger) return <></>;
		if (!menuList || menuList.length === 0) return <></>;
		return (
			<StyledAntMenu selectedKeys={[selected.id]} triggerSubMenuAction={triggerSubMenuAction}>
				{
					menuList.map((menuItem: IMenuItem) => renderMenuItem(menuItem))
				}
			</StyledAntMenu>
		);
	};

	return (
		<>
			<GlobalStyle />
			<StyledAntDropdown
				{...popupContainerKey ? { getPopupContainer: () => document.getElementById(popupContainerKey) } : {}}
				overlay={renderMenu(menu)}
				onVisibleChange={setOnTrigger}
				trigger={triggerSubMenuAction}
				className={`${className} ${onTrigger && 'menu-open'}`}
				disabled={disabled}
				onClick={() => {
					if (!disabled && onDropdownClick) {
						onDropdownClick();
					}
				}}
			>
				{
					component ? component({ selectText: selected.text, onTrigger }) : (
						<Content className="dropdown-content">
							<StyleSelected
								$isDisabled={disabled}
								onClick={e => e.preventDefault()}
								className="dropdown-selected"
							>
								<TextContent
									className="text-content"
									$isRestItem={selected.isReset}
									$isPlaceholder={isPlaceholder}
								>
									{selected.text}
								</TextContent>
							</StyleSelected>
							<IconContent isOpenDropdown={onTrigger} className="dropdown-icon-content">
								<StyledArrowDownIcon />
							</IconContent>
						</Content>
					)
				}
			</StyledAntDropdown>
		</>
	);
});

export default Dropdown;
