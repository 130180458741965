import { createAction } from 'redux-actions';
import {
	GET_PUNCH_IN_CALL,
	GET_PUNCH_IN_DONE,
	GET_PUNCH_IN_FAIL,
	POST_PUNCH_IN_RECORD_CALL,
	POST_PUNCH_IN_RECORD_DONE,
	POST_PUNCH_IN_RECORD_FAIL,
	GET_DAY_SHIFTS_CALL,
	GET_DAY_SHIFTS_DONE,
	GET_DAY_SHIFTS_FAIL,
} from '@src/store/types';

export const getPunchInCall = createAction(GET_PUNCH_IN_CALL);
export const getPunchInDone = createAction(GET_PUNCH_IN_DONE);
export const getPunchInFail = createAction(GET_PUNCH_IN_FAIL);
export const postPunchInRecordCall = createAction(POST_PUNCH_IN_RECORD_CALL);
export const postPunchInRecordDone = createAction(POST_PUNCH_IN_RECORD_DONE);
export const postPunchInRecordFail = createAction(POST_PUNCH_IN_RECORD_FAIL);
export const getDayShiftsCall = createAction(GET_DAY_SHIFTS_CALL);
export const getDayShiftsDone = createAction(GET_DAY_SHIFTS_DONE);
export const getDayShiftsFail = createAction(GET_DAY_SHIFTS_FAIL);
