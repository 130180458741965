export const GET_PUNCH_IN_CALL = 'GET_PUNCH_IN_CALL';
export const GET_PUNCH_IN_DONE = 'GET_PUNCH_IN_DONE';
export const GET_PUNCH_IN_FAIL = 'GET_PUNCH_IN_FAIL';

export const POST_PUNCH_IN_RECORD_CALL = 'POST_PUNCH_IN_RECORD_CALL';
export const POST_PUNCH_IN_RECORD_DONE = 'POST_PUNCH_IN_RECORD_DONE';
export const POST_PUNCH_IN_RECORD_FAIL = 'POST_PUNCH_IN_RECORD_FAIL';

export const GET_DAY_SHIFTS_CALL = 'GET_DAY_SHIFTS_CALL';
export const GET_DAY_SHIFTS_DONE = 'GET_DAY_SHIFTS_DONE';
export const GET_DAY_SHIFTS_FAIL = 'GET_DAY_SHIFTS_FAIL';
