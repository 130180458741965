import React from 'react';
import useTranslation from '@src/hooks/useTranslation';
import Img from 'react-image';
import { useDispatch, useSelector } from 'react-redux';
import { historyPushCall } from '@src/store/actions';
// import MyButton from '@src/components/myButton';
import { Row, Col } from 'antd';
import * as selectors from '@src/store/selectors';
import { find } from 'lodash';
import styled, { ThemeProvider } from 'styled-components';

import { colorThemes } from '@src/const/theme';
import device from '@src/styles/breakpoint';
import img from '@src/assets/images/404.svg';

import { Wrapper } from './Styled';

const StyledButton = styled.div`

	background: #3091FD;
	color: #FFFFFF;
	border-radius: 4px;
	padding: 12px 20px;
	cursor: pointer;
	margin-top: 32px;
	width: 246px;
	text-align: center;
	@media (max-width: 768px) {
    width: 335px;
  }
`;

const NoCoursePage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { themes } = useSelector(selectors.getThemeSelector);
	const handleBackHome = () => {
		dispatch(historyPushCall('/'));
	};

	const themeName = themes.color_theme;
	const selectedTheme = find(colorThemes, theme => theme.id === themeName) || colorThemes[0];
	const themeObj = {
		device,
		colors: selectedTheme?.colors,
	};

	return (
		<ThemeProvider theme={themeObj}>
			<Wrapper>
				<Img className="img" src={img} />
				<div className="text">
					<div className="title">{t('all:404_not_found')}</div>
					<div className="sub-title">
						{t('all:404_not_found_desc')}
					</div>
				</div>
				<StyledButton
					role="presentation"
					onClick={handleBackHome}
				>
					{t('errors:backHome')}
				</StyledButton>
			</Wrapper>
		</ThemeProvider>
	);
};

export default NoCoursePage;
