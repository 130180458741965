import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Modal, { DescText, ButtonWrapper } from '@src/components/pwa/modal';
import useTranslation from '@src/hooks/useTranslation';
import {
	BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR,
} from '@src/const/common';
import Button from '@src/components/common/button';

import { recordIssueStart } from '@src/store/actions/session';
import { getUserProfile } from '@src/store/selectors/user';

export const SubmitButton = styled(Button)`
	width: 120px;
	height: 40px;
	padding: 8px;
	@media ${props => props.theme.device.mobile} {
		width: 100%;
	}
`;

const StyledModal = styled(Modal)`
	.modal-footer {
		@media ${props => props.theme.device.mobile} {
			bottom: 58px;
		}
	}
`;

interface IModal {
	isActive: boolean;
	handleClose: () => void;
}

const RecordIssueModal = ({
	isActive, handleClose,
}: IModal):JSX.Element => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(getUserProfile) as any;

	return (
		<StyledModal
			isActive={isActive}
			headerText={t('all:report_issue')}
			handleClose={handleClose}
			status="warning"
			footer={(
				<ButtonWrapper>
					<SubmitButton
						variant={BUTTON_VARIANT_CONTAINED_DEFAULT_COLOR}
						handleClick={() => {
							dispatch(
								recordIssueStart({
									userId: user.id,
									name: `${user.school_name}_${user.name}`,
									email: user.email,
									schoolName: user.school_name,
								}),
							);
							handleClose();
						}}
					>
						{t('all:start_operation')}
					</SubmitButton>

				</ButtonWrapper>
			)}
		>
			<div>
				<DescText>{t('all:report_issue_desc')}</DescText>
			</div>
		</StyledModal>
	);
};

export default RecordIssueModal;
