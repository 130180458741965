/* eslint-disable max-len */
export const GET_SHIFT_CALL = 'GET_SHIFT_CALL';
export const GET_SHIFT_DONE = 'GET_SHIFT_DONE';
export const GET_SHIFT_FAIL = 'GET_SHIFT_FAIL';

export const POST_SHIFT_CALL = 'POST_SHIFT_CALL';
export const POST_SHIFT_DONE = 'POST_SHIFT_DONE';
export const POST_SHIFT_FAIL = 'POST_SHIFT_FAIL';

export const DELETE_SHIFT_CALL = 'DELETE_SHIFT_CALL';
export const DELETE_SHIFT_DONE = 'DELETE_SHIFT_DONE';
export const DELETE_SHIFT_FAIL = 'DELETE_SHIFT_FAIL';

export const GET_SHIFT_DETAIL_CALL = 'GET_SHIFT_DETAIL_CALL';
export const GET_SHIFT_DETAIL_DONE = 'GET_SHIFT_DETAIL_DONE';
export const GET_SHIFT_DETAIL_FAIL = 'GET_SHIFT_DETAIL_FAIL';

export const PATCH_SHIFT_CALL = 'PATCH_SHIFT_CALL';
export const PATCH_SHIFT_DONE = 'PATCH_SHIFT_DONE';
export const PATCH_SHIFT_FAIL = 'PATCH_SHIFT_FAIL';

export const GET_SHIFT_SCHEDULERS_SETTINGS_CALL = 'GET_SHIFT_SCHEDULERS_SETTINGS_CALL';
export const GET_SHIFT_SCHEDULERS_SETTINGS_DONE = 'GET_SHIFT_SCHEDULERS_SETTINGS_DONE';
export const GET_SHIFT_SCHEDULERS_SETTINGS_FAIL = 'GET_SHIFT_SCHEDULERS_SETTINGS_FAIL';

export const POST_SHIFT_SCHEDULERS_SETTINGS_CALL = 'POST_SHIFT_SCHEDULERS_SETTINGS_CALL';
export const POST_SHIFT_SCHEDULERS_SETTINGS_DONE = 'POST_SHIFT_SCHEDULERS_SETTINGS_DONE';
export const POST_SHIFT_SCHEDULERS_SETTINGS_FAIL = 'POST_SHIFT_SCHEDULERS_SETTINGS_FAIL';

export const GET_SHIFT_SCHEDULES_CALL = 'GET_SHIFT_SCHEDULES_CALL';
export const GET_SHIFT_SCHEDULES_DONE = 'GET_SHIFT_SCHEDULES_DONE';
export const GET_SHIFT_SCHEDULES_FAIL = 'GET_SHIFT_SCHEDULES_FAIL';

export const POST_SHIFT_SCHEDULES_CALL = 'POST_SHIFT_SCHEDULES_CALL';
export const POST_SHIFT_SCHEDULES_DONE = 'POST_SHIFT_SCHEDULES_DONE';
export const POST_SHIFT_SCHEDULES_FAIL = 'POST_SHIFT_SCHEDULES_FAIL';

export const DELETE_SHIFT_SCHEDULES_CALL = 'DELETE_SHIFT_SCHEDULES_CALL';
export const DELETE_SHIFT_SCHEDULES_DONE = 'DELETE_SHIFT_SCHEDULES_DONE';
export const DELETE_SHIFT_SCHEDULES_FAIL = 'DELETE_SHIFT_SCHEDULES_FAIL';

export const GET_SHIFT_SCHEDULES_DETAIL_CALL = 'GET_SHIFT_SCHEDULES_DETAIL_CALL';
export const GET_SHIFT_SCHEDULES_DETAIL_DONE = 'GET_SHIFT_SCHEDULES_DETAIL_DONE';
export const GET_SHIFT_SCHEDULES_DETAIL_FAIL = 'GET_SHIFT_SCHEDULES_DETAIL_FAIL';

export const PATCH_SHIFT_SCHEDULES_CALL = 'PATCH_SHIFT_SCHEDULES_CALL';
export const PATCH_SHIFT_SCHEDULES_DONE = 'PATCH_SHIFT_SCHEDULES_DONE';
export const PATCH_SHIFT_SCHEDULES_FAIL = 'PATCH_SHIFT_SCHEDULES_FAIL';

export const POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL = 'POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL';
export const POST_SHIFT_SCHEDULES_ASSIGNMENTS_DONE = 'POST_SHIFT_SCHEDULES_ASSIGNMENTS_DONE';
export const POST_SHIFT_SCHEDULES_ASSIGNMENTS_FAIL = 'POST_SHIFT_SCHEDULES_ASSIGNMENTS_FAIL';

export const GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL = 'GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL';
export const GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_DONE = 'GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_DONE';
export const GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_FAIL = 'GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_FAIL';

export const GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL = 'GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL';
export const GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_DONE = 'GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_DONE';
export const GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_FAIL = 'GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_FAIL';

export const GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL = 'GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL';
export const GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE = 'GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE';
export const GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL = 'GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL';

export const POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL = 'POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL';
export const POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE = 'POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE';
export const POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL = 'POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL';

export const GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL = 'GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL';
export const GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_DONE = 'GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_DONE';
export const GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_FAIL = 'GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_FAIL';

export const POST_SHIFT_SCHEDULES_PUBLISH_CALL = 'POST_SHIFT_SCHEDULES_PUBLISH_CALL';
export const POST_SHIFT_SCHEDULES_PUBLISH_DONE = 'POST_SHIFT_SCHEDULES_PUBLISH_DONE';
export const POST_SHIFT_SCHEDULES_PUBLISH_FAIL = 'POST_SHIFT_SCHEDULES_PUBLISH_FAIL';

export const GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL = 'GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL';
export const GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_DONE = 'GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_DONE';
export const GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_FAIL = 'GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_FAIL';

export const GET_MEMBERS_CALL = 'GET_MEMBERS_CALL';
export const GET_MEMBERS_DONE = 'GET_MEMBERS_DONE';
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';
