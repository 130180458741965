import { createAction } from 'redux-actions';
import {
	GET_DEPARTMENTS_CALL,
	GET_DEPARTMENTS_DONE,
	GET_DEPARTMENTS_FAIL,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE,
	GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL,
	GET_DEPARTMENTS_LEARNING_RECORDS_CALL,
	GET_DEPARTMENTS_LEARNING_RECORDS_DONE,
	GET_DEPARTMENTS_LEARNING_RECORDS_FAIL,
	GET_DEPARTMENTS_EMPLOYEES_CALL,
	GET_DEPARTMENTS_EMPLOYEES_DONE,
	GET_DEPARTMENTS_EMPLOYEES_FAIL,
} from '@src/store/types';

export const getDepartmentsCall = createAction(GET_DEPARTMENTS_CALL);
export const getDepartmentsDone = createAction(GET_DEPARTMENTS_DONE);
export const getDepartmentsFail = createAction(GET_DEPARTMENTS_FAIL);

export const getOwnedDepartmentCall = createAction(GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL);
export const getOwnedDepartmentDone = createAction(GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE);
export const getOwnedDepartmentFail = createAction(GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL);

export const getDepartmentsLearningRecordsCall = createAction(GET_DEPARTMENTS_LEARNING_RECORDS_CALL);
export const getDepartmentsLearningRecordsDone = createAction(GET_DEPARTMENTS_LEARNING_RECORDS_DONE);
export const getDepartmentsLearningRecordsFail = createAction(GET_DEPARTMENTS_LEARNING_RECORDS_FAIL);

export const getDepartmentsEmployeesCall = createAction(GET_DEPARTMENTS_EMPLOYEES_CALL);
export const getDepartmentsEmployeesDone = createAction(GET_DEPARTMENTS_EMPLOYEES_DONE);
export const getDepartmentsEmployeesFail = createAction(GET_DEPARTMENTS_EMPLOYEES_FAIL);
