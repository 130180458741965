import styled from 'styled-components';
import { Table } from 'antd';

import Button from '@src/components/common/button';

interface IStyledTableProps {
	isEmpty: boolean;
}

export const StyledTable = styled(Table)`
	
	.ant-table-row {
		cursor: pointer;
		& td:first-child {
			min-height: 81px;
		}
	}

	.ant-table-row-level-1 td{
		background: #F5F5F7;
	}

	.ant-table-tbody {
		overflow-y: hidden;
	}
	.ant-pagination-item-ellipsis {
		font-family: 'Noto Sans', sans-serif !important;
	}

	.ant-spin-container {
		display: flex;
		flex-direction: column;
	}

	.ant-table-pagination {
		display: flex;
		align-items: center;
	}

	.ant-select-selector {
		height: 32px !important;
	}

	.ant-select-selection-item {
		height: 32px;
		display: flex;
		align-items: center;
	}

	.ant-pagination-item-active {
		background: #3091FD;
		& > a {
			color: #FFFFFF;
		}
	}

	.ant-pagination-total-text {
		font-size: 16px;
		line-height: 32px;
	}

	button[class*="ant-pagination-item"], .ant-pagination-item, .ant-select-selector {
		border-radius: 4px !important;
	}

	.ant-table {
		width: 100%;
	}

	.ant-table-tbody > tr > td {
		text-align: center;
		${(props: IStyledTableProps) => (props.isEmpty ?
		'border-bottom: 1px solid #E9E9E9;' :
		'border-bottom: none;')}
	}

	thead[class*="ant-table-thead"] th {
		background: #F9F9F9;
	}

	th[class*="ant-table-cell"] {
		color: #222222;
		font-weight: 400;
		text-align: center;
		&:before {
			width: 0px !important;
		}
	}

	tr[class*="ant-table-placeholder"] > td[class*="ant-table-cell"] {
		padding-top: 40px;
		text-align: center;
	}

	.table__table-row {
		cursor: pointer;
	}
	.ant-spin-dot {
		font-size: 80px;
		left: calc(50% - 40px) !important;
	}

	.ant-table-tbody > tr > td, .ant-table-thead > tr > th {
		padding: 16px 22px;
		@media ${props => props.theme.device.mobile} {
			padding: 16px 8px;
		}
	}
	.ant-table-sticky-scroll-bar {
		display: none;
	}
	td[colspan="0"] {
		display: none;
	}
`;

export const ActionContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

`;

export const ActionItem = styled(Button)`
	font-weight: normal;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 12px;
	background: rgba(0,0,0,0);
	color: #3091fd;
	color: ${(props: { isDisable?: boolean; }) => (props.isDisable ? '#B9B9B9 !important' : '#3091fd')};
	&:first-child {
		padding-left: 0px;
	}
	&:last-child {
		padding-right: 0px;
	}
	:hover {
		color: ${(props: { isDisable?: boolean; }) => (props.isDisable ? '#B9B9B9 !important' : '#005ABE')};
	}
	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			padding: 0px 6px;
			&:first-child {
				padding-left: 6px;
			}
			&:last-child {
				padding-right: 6px;
			}
		}
`;

export const ActionDivider = styled.div`
	height: 8px;
	width: 1px;
	border-left: 1px solid #B9B9B9;
`;

export const StyledTextWrap = styled.div`
	text-align: center;
	white-space: pre-wrap;
	color: #222222;
`;

export const StyledNumberWrap = styled(StyledTextWrap)`
	text-align: center;
`;

export const StyledDisableTextWrap = styled.div`
	text-align: center;
	white-space: pre-wrap;
	color: #B9B9B9;
	fontWeight: 300;
`;

export const StyledLightTextWrap = styled.div`
	text-align: center;
	white-space: pre-wrap;
	font-weight: 300;
	font-size: 14px;
	color: #676767;
`;

export const StyledNameText = styled.div`
	text-align: center;
	color: #3091FD;
	:hover {
		color: #005ABE;
	}
`;

export const ActionContentMobileVertical = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;

	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			flex-direction: column;
		}
`;

export const ActionDividerMobileVertical = styled.div`
	height: 8px;
	width: 1px;
	border-left: 1px solid #B9B9B9;

	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			display: none;
		}
`;

export const ActionItemMobileVertical = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 12px;
	background: rgba(0,0,0,0);
	&:first-child {
		padding-left: 0px;
	}

	&:last-child {
		padding-right: 0px;
	}

	@media 
		${props => props.theme.device.mobile},
		${props => props.theme.device.smTablet}
		{
			padding: unset;
			margin-top: 4px;
			&:first-child {
				margin-top: 0px;
			}
		}
`;

export const DeleteBtn = styled(ActionItem)`
	color: #D0021B;
	:hover {
		color: #AE0015;
		background-color: unset;
	}
`;
export const EditBtn = styled(ActionItem)`
	color: #3091fd;
	border: unset;
	:hover {
		color: #005ABE;
		background-color: unset;
	}
`;
export const CancelBtn = styled(ActionItem)`
	color: #676767;
	:hover {
		color: #333333
	}
`;

export const CenterTextStyled = styled.div`
	text-align: center;
	white-space: nowrap;
	font-size: 14px;
	white-space: pre-line;
`;

export const LineTable = styled(StyledTable)`
	.table__row {
		cursor: pointer;
		& td:first-child {
			min-height: 81px;
		}
	}
	.ant-table-row-level-1 td{
		background: #F9F9F9;
	}

	.ant-table-cell {
		padding: 12px 3px !important;
	}
	.ant-table-column-sorters {
		padding: 0px;
	}

	.split-column {
		padding: 16px 0 0 0 !important;
		border-bottom: 0px !important;
	}

	.ant-table-header {
		border-right: 1px solid #B9B9B9;
		border-bottom: 0px !important;
	}
	td {
		${(props: IStyledTableProps) => (props.isEmpty ?
		`
			border-bottom: unset !important;
			border-right: unset !important;
		` : `
			border-bottom: 1px solid #B9B9B9 !important;
			border-right: 1px solid #B9B9B9 !important;
		`)}
	}
	.table__row td:first-child {
		border-left: 1px solid #B9B9B9 !important;
	}
	.ant-table-thead > tr > th {
			border-right: 1px solid #B9B9B9 !important;
			border-top: 1px solid #B9B9B9 !important;
			${(props: IStyledTableProps) => (props.isEmpty && `
				border-bottom: 1px solid #B9B9B9 !important;
			`)}
		
			padding-right: 2px;
			&.child-head {
				border-top: 0px !important;
				border-right: 0px !important;
				margin-right: -1px;
			}
			&.last-child-head {
				border-top: 0px !important;
				border-right: 1px solid #B9B9B9 !important;
			}
			&.child-head:first-child {
				border-left: 0px !important;
			}
	}
	.ant-table-thead > tr > th:first-child {
		border-left: 1px solid #B9B9B9 !important;
	}
`;
