import React from 'react';
import { Tabs } from 'antd';

import styled from 'styled-components';

import Input, { IInput } from '@src/components/common/form/input';
import {
	StyledTable as Table,
} from '@src/components/common/styles/table';
import Button from '@src/components/common/button';

export const Label = styled.div`
	color: #222;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 8px;
	${props => props.isRequired && `
		&:after {
			content: '*';
			color: #D0021B;
			padding-left: 5px;
		}
	`}
`;

export const CalendarInput = styled.div`
	display: flex;
	height: 40px;
	padding: 8px 12px;
	align-items: center;
	position: relative;
	background: #FFF;
	border-bottom: 1px solid #E9E9E9;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	color: #222;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	${props => props.isPlaceholder && `
		color: #676767;
		font-weight: 300;
	`}
	& :first-child {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		${props => props.isViewMode && `
			border-bottom: 0px;
		`}
	}
	& :last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		${props => !props.isViewMode && `
			border-bottom: 0px;
		`}
	}
	& :not(:first-child):not(:last-child) {
		${props => props.isViewMode && `
			border-bottom: 0px;
		`}
	}
	svg {
		position: absolute;
		right: 12px;
	}
	${props => props.isViewMode && `
		background: #F5F5F7;
		border: 1px solid #E9E9E9;
		color: #676767;
		font-weight: 400;
	`}
	${props => props.disabled && `
		color: #B9B9B9;
	`}
`;

const StyledTextArea = styled(Input)`
	margin-bottom: 0px !important;
	.input {
		resize: vertical;
		border: 0px;
		${props => props.isViewMode && `
			background: #F5F5F7;
			border: 1px solid #E9E9E9;
		`}
	}
`;

const StyleInputWrapper = styled.div`
	position: relative;
`;

const TextLength = styled.div`
	position: absolute;
	right: 12px;
	bottom: 11px;
	font-size: 12px;
	color: #B9B9B9;
	text-align: right;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
`;

export interface ITextAreaInput extends IInput{
	value?: string;
	isViewMode?: boolean;
}

export const TextAreaInput = (props: ITextAreaInput): JSX.Element => {
	const { value = '', isViewMode, maxLength = 120 } = props;
	return (
		<StyleInputWrapper>
			<StyledTextArea
				{...props}
				component="textarea"
				isViewMode={isViewMode}
			/>
			<TextLength>
				{`${value?.length}/${maxLength}`}
			</TextLength>
		</StyleInputWrapper>
	);
};

export const StyledTable = styled(Table)`
	.ant-table-row {
		cursor: pointer;
		& td:first-child {
			min-height: 81px;
		}
	}
	.ant-table-row-level-1 td {
		background: #F9F9F9;
	}

	.ant-table-cell {
		padding: 12px 3px !important;
	}
	.ant-table-column-sorters {
		padding: 0px;
	}
	.ant-table-row-expand-icon-cell {
		width: 0px !important;
	}
	.ant-table-expanded-row td {
		background: #FFFFFF;
	}

	.notify {
		text-align: left !important;
	}
	td.notify {
		padding-left: 30px !important;
	}

	@media 
		${props => props.theme.device.smTablet},
		${props => props.theme.device.mobile} {
		.notify {
			text-align: center !important;
		}
		td.notify {
			padding-left: unset !important;
		}
	}
	
	thead[class*="ant-table-thead"] th {
		background: #FFF !important;
	}

	.ant-table {
		border-radius: 12px !important;
	}
	.ant-table-content {
		border-radius: 12px !important;
	}

	.ant-table-tbody > tr:last-child > td {
		border-bottom: 0px;
	}

	.ant-empty {
		height: 400px;
		.ant-empty-image {
			display: none;
		}
		.ant-empty-description {
			display: none;
		}
	}

	.ant-table-tbody > tr > td {
		border-bottom: 1px solid #E9E9E9;
	}
`;

export const NotifyDot = styled.div`
	width: 6px;
	height: 6px;
	background-color: #D0021B;
	border-radius: 50%;
`;

const StyledListTabs = styled(Tabs)`
	&.ant-tabs .ant-tabs-nav {
		margin: unset;
		background-color: ${(props: { isInLine: boolean }) => (props.isInLine ? 'transparent' : '#FFF')} !important;
		width: 100% !important;
		border-bottom-color:
			${(props: { isInLine: boolean }) => (props.isInLine ? '#E9E9E9' : 'transparent')};
		border-bottom-width: 1px !important;
		border-bottom-style: solid !important;
	}
	&.ant-tabs .ant-tabs-tab {
		font-weight: 300;
		font-size: 16px !important;
		color: #222222 !important;
		display: flex;
		justify-content: center;
		width: 50% !important;
	}
	&.ant-tabs .ant-tabs-ink-bar {
		height: 4px !important;
	}
	&.ant-tabs .ant-tabs-tab-active {
		text-shadow: unset !important;
		font-weight: 400;
		svg {
			fill: #3091FD !important;
		}
	}
	&.ant-tabs .ant-tabs-tab-btn {
		text-shadow: unset !important;
		span {
			display: flex;
			align-items: center;
		}
		svg {
			fill: #333;
		}
		:hover {
			color: #3091FD;
			svg {
				fill: #3091FD;
			}
		}
	}
	&.ant-tabs .ant-tabs-nav-wrap {
		justify-content: flex-start !important;
		padding: 0 20px;
		display: inline-block;
	}
	&.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
		margin: 0 20px 0 0;
	} 
	& .ant-tabs-nav-more {
		display: none;
	}
	& .ant-tabs-nav-list {
		width: 100% !important;
	}
	@media 
	${props => props.theme.device.mobile},
	${props => props.theme.device.smTablet}
	{
		&.ant-tabs .ant-tabs-nav-wrap {
			padding: 0 12px;
		}
	}
`;

export const ListTabs = (props) => {
	const isInLine = navigator?.userAgent?.toLowerCase().includes('line');
	return (
		<StyledListTabs isInLine={isInLine} {...props} />
	);
};

export const NotifyNumber = styled.div`
	position: absolute;
	right: 6px;
	top: 12px;
	background: #D0021B;
	border-radius: 8px;
	color: #FFF;
	text-align: center;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	height: 15px;
	padding: 0px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const FixedBottomWrapper = styled.div`
	position: sticky;
	width: 100%;
	bottom: 0px;
	background: #FFF;
	box-shadow: 0px 0px 12px 0px rgba(69, 65, 78, 0.09);
	padding: 12px 32px;
	@media 
	${props => props.theme.device.mobile} {
		position: fixed;
		padding-bottom: calc(12px + env(safe-area-inset-bottom));
	}
`;

export const FixedBottomButton = styled(Button)`
	width: 100%;
	height: 40px;
	padding: 8px;
`;
