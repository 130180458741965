import React, { useRef } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import * as actionCreators from '@src/store/actions';
import * as selectors from '@src/store/selectors';
import { MODAL_TYPE_LOGIN } from '@src/const/common';

import { IconComponent } from '@src/icons';
import { useBottomKeepSpace } from '@src/components/bottomKeepSpace';
import useTranslation from '@src/hooks/useTranslation';
import classes from './styles.module.less';

const ICON_SIZE = 24;
const ICON_COLOR_DEFAULT = '#b4b4b4';
const ICON_COLOR_ACTIVE = '#676767';

const calcIsActiveEqual = (link, pathname) => link === pathname;
const calcIsActivePrefixEqual = (link, pathname) =>
  pathname.indexOf(link) === 0;

function BottomNavigation() {
  const { t } = useTranslation();
  const MENU_LIST = [
    {
      icon: 'icon_mobile_home_37',
      iconActive: 'icon_mobile_home_38',
      name: t('common:home'),
      link: '/training',
      calcIsActive: calcIsActiveEqual,
      shouldSignedIn: false,
    },
    // TODO: Next Sprint
    // {
    //   icon: 'icon_search_21',
    //   iconActive: 'icon_mobile_search_39',
    //   name: '探索課程',
    //   link: '/search',
    //   calcIsActive: calcIsActiveEqual,
    //   shouldSignedIn: false,
    // },
    {
      icon: 'icon_mobile_course_40-1',
      iconActive: 'icon_mobile_course_41-1',
      name: t('account:courses'),
      link: '/account/courses',
      calcIsActive: calcIsActivePrefixEqual,
      shouldSignedIn: true,
    },
    // Hide my order entrance temporarily.
    // {
    //   icon: 'icon_mobile_order_42',
    //   iconActive: 'icon_mobile_order_active_42',
    //   name: '我的訂單',
    //   link: '/account/orders',
    //   calcIsActive: calcIsActivePrefixEqual,
    //   shouldSignedIn: true,
    // },
    {
      icon: 'icon_mobile_account_43',
      iconActive: 'icon_mobile_account_44',
      name: t('all:account'),
      link: '/account/settings',
      calcIsActive: calcIsActivePrefixEqual,
      shouldSignedIn: true,
    },
  ];

  const boxDOM = useRef();
  useBottomKeepSpace(boxDOM);
  const dispatch = useDispatch();
  const router = useRouter();
  const isSignedIn = useSelector(selectors.getIsSignIn);
  const { footerContent } = useSelector(selectors.getThemeSelector);
  const { backColor, textColor } = footerContent;
  const backColorStyle = {
    backgroundColor: backColor,
    borderColor: backColor,
  };
  const textColorStyle = {
    color: textColor,
  };

  return (
    <div ref={boxDOM} className={classes.box} style={backColorStyle}>
      {MENU_LIST.map((menu, index) => {
        const isActive = menu.calcIsActive(menu.link, router.asPath);

        return (
          <button
            key={index}
            className={cx(classes.itemBtn, isActive && classes.active)}
            onClick={() => {
              if (menu.shouldSignedIn && !isSignedIn) {
                dispatch(actionCreators.setModalType({ type: MODAL_TYPE_LOGIN }));
              } else {
                router.push(menu.link);
              }
            }}
            style={textColorStyle}
          >
            <IconComponent
              size={ICON_SIZE}
              icon={isActive ? menu.iconActive : menu.icon}
            />
            <p className={classes.itemText}>{menu.name}</p>
          </button>
        );
      })}
    </div>
  );
}

export default BottomNavigation;
