import moment from 'moment';

export const handleGetDateDuration = ({ startDate, endDate }) => {
  const end = moment(endDate);
  const duration = moment.duration(end.diff(moment(startDate)));
  const dates = {
    days: Math.floor(duration.asDays()),
    hours: duration.hours(),
    minutes: duration.minutes(),
    seconds: duration.seconds(),
  };
  let dateType;
  let date;
  let days = duration.asDays();
  let hours = duration.asHours();
  let minutes = duration.asMinutes();

  if (days >= 1) {
    dateType = 'day';
    date = Math.ceil(days);
  } else if (hours >= 1) {
    dateType = 'hours';
    date = Math.ceil(hours);
  } else if (minutes >= 1) {
    dateType = 'minutes';
    date = Math.ceil(minutes);
  } else {
    dateType = 'minutes';
    date = 0;
  }

  return {
    date,
    dateType,
    dates,
  };
};

export const calculateWorkHours = (data) => {
	const shiftStart = moment()
		.set({
			hour: data.shift_time_start_hour,
			minute: data.shift_time_start_minute,
			second: 0,
		});
	const shiftEnd = moment()
		.set({
			hour: data.shift_time_end_hour,
			minute: data.shift_time_end_minute,
			second: 0,
		});

	// 如果結束時間在開始時間之前，表示跨天了
	if (shiftEnd.isBefore(shiftStart)) {
		shiftEnd.add(1, 'day');
	}

	const totalShiftDuration = moment.duration(shiftEnd.diff(shiftStart));

	// 減去休息時間
	// eslint-disable-next-line no-restricted-syntax
	for (const unpaidDuration of (data?.unpaid_durations || [])) {
		const unpaidStart = moment()
			.set({
				hour: unpaidDuration.unpaid_duration_start_hour,
				minute: unpaidDuration.unpaid_duration_start_minute,
				second: 0,
			});
		const unpaidEnd = moment()
			.set({
				hour: unpaidDuration.unpaid_duration_end_hour,
				minute: unpaidDuration.unpaid_duration_end_minute,
				second: 0,
			});

		// 如果休息的結束時間在開始時間之前，表示休息時間跨天了
		if (unpaidEnd.isBefore(unpaidStart)) {
			unpaidEnd.add(1, 'day');
		}

		// 有 bug 暫時先不檢查，直接扣除
		const unpaidDurationTime = moment.duration(unpaidEnd.diff(unpaidStart));
		totalShiftDuration.subtract(unpaidDurationTime);
	}

	// 計算小時和分鐘
	const hours = Math.max(0, Math.floor(totalShiftDuration.asHours()));
	const minutes = totalShiftDuration.minutes();
	const totalMinutes = totalShiftDuration.asMinutes();

	return { hours, minutes, totalMinutes };
};

