import React, { Component, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import * as actionCreators from '@src/store/actions';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';

import {
	DefaultBanner,
	VideoBanner,
	Cards,
	ImageText,
	TextImage,
	Texts,
	CourseProducts,
	OfflineEvents,
	LearningPaths,
	LearningAreas,
	// CourseEvaluation,
	TextView,
	ImageComponent,
	ImagesComponent,
	CarouselBanner,
	Promotion,
	TeacherInfo,
	UserLogo,
	FAQComponent,
	TextBackground,
	VideoComponent,
	ButtonComponent,
	ButtonBackground,
	CountDown,
	TextVideo,
	VideoText,
} from '@src/components/home';
import { isPrivatePageNeedLogin, clearQueryString, checkIsServer } from '@src/utils/common';
import NotMatchPage from '@src/pages/NotMatchPage';
import RedirectClosedLogin from '@src/pages/redirectClosedLogin';
import BottomNavigation from '@src/components/bottomNavigation';
import JobList from '@src/components/home/themeComponents/jobList';
import _ from 'lodash';
import classNames from 'classnames';
import { getThemeSelector, getDomain } from '@src/store/selectors';
import { getRedirectUrl } from '@src/utils/hardcode';

import classStyle from './HomePage.module.less';
import ContentComponent from './ContentComponent';

class HomePage extends Component {
	renderContent = (item, index) => {
		const {
			t,
			pages,
			router: { pathname },
			homePage,
			ssrProps,
		} = this.props;

		const themePage = _.find(pages, { allPath: pathname }) || homePage;
		const isHomePage = themePage.content.key === 'home';
		const { type, key, backColor = 'transparent' } = item;
		let isContentFullItem = false;
		let Content;
		let useFontColor = true;
		let isMainColorLight = false;
		let useCommonPadding = true;

		switch (type) {
			case 'header':
				break;
			case 'imageBanner':
				Content = DefaultBanner;
				isContentFullItem = true;
				break;
			case 'videoBanner':
				Content = VideoBanner;
				isContentFullItem = true;
				break;
			case 'card3':
				Content = Cards;
				break;
			case 'cards':
				Content = Cards;
				break;
			case 'imageText':
				Content = ImageText;
				break;
			case 'textImage':
				Content = TextImage;
				break;
			case 'twoText':
				Content = Texts;
				break;
			case 'text3':
				Content = Texts;
				break;
			case 'textBackground':
				Content = TextBackground;
				isContentFullItem = true;
				useFontColor = false;
				break;
			case 'courseProducts':
				Content = CourseProducts;
				useFontColor = false;
				useCommonPadding = false;
				break;
			case 'learningPath':
				Content = LearningPaths;
				useFontColor = false;
				useCommonPadding = false;
				break;
			case 'learningArea':
				Content = LearningAreas;
				useFontColor = false;
				useCommonPadding = false;
				break;
			case 'offlineEvents':
				Content = OfflineEvents;
				useFontColor = false;
				useCommonPadding = false;
				break;
				// case 'courseEvaluation':
				//   Content = CourseEvaluation;
				//   useFontColor = false;
				//   break;
			case 'promotion':
				Content = Promotion;
				isContentFullItem = true;
				useFontColor = false;
				break;
			case 'footer':
				break;
			case 'text':
				Content = TextView;
				break;
			case 'image':
				Content = ImageComponent;
				isContentFullItem = true;
				break;
			case 'images':
				Content = ImagesComponent;
				break;
			case 'carouselBanner':
				Content = CarouselBanner;
				isContentFullItem = true;
				break;
			case 'teacherInfo':
				Content = TeacherInfo;
				isMainColorLight = true;
				break;
			case 'userLogo':
				Content = UserLogo;
				break;
			case 'FAQ':
				Content = FAQComponent;
				isMainColorLight = true;
				useFontColor = false;
				break;
			case 'video':
				Content = VideoComponent;
				break;
			case 'button':
				Content = ButtonComponent;
				break;
			case 'buttonBackground':
				Content = ButtonBackground;
				isContentFullItem = true;
				break;
			case 'countDown':
				Content = CountDown;
				break;
			case 'videoText':
				Content = VideoText;
				break;
			case 'textVideo':
				Content = TextVideo;
				break;
			case 'job':
				Content = JobList;
				isMainColorLight = false;
				useFontColor = false;
				break;
			default:
				break;
		}

		if (!Content) {
			return null;
		}

		const itemClassName = useCommonPadding ? classNames([
			classStyle.item,
			!isContentFullItem && classStyle.commonPaddingItem,
			isMainColorLight && classStyle.commonMainColorLight,
			isHomePage && useFontColor && classStyle.fontColor,
		]) : classNames([
			classStyle.item,
			isMainColorLight && classStyle.commonMainColorLight,
			isHomePage && useFontColor && classStyle.fontColor,
		]);

		const contentClassName = classNames([
			isContentFullItem ?
				classStyle.contentFullItem :
				classStyle.contentHomeItem,
		]);
		const backColorStyle = {
			backgroundColor: backColor !== 'transparent' && backColor,
		};

		return (
			<ContentComponent
				key={index}
				type={type}
				itemKey={key}
				item={item}
				backColorStyle={backColorStyle}
				isHomePage={isHomePage}
				ssrProps={ssrProps}
				pages={pages}
				contentClassName={contentClassName}
				itemClassName={itemClassName}
				Content={Content}
			/>
		);
	};

	render() {
		const { homePage, pages, router, themes, domain } = this.props;
		const { closed_web: closedWeb } = themes;
		let themePage = null;

		if (router.pathname === '/training' || router.pathname === '/preview') {
			themePage = homePage;
		} else {
			themePage = _.find(pages, {
				allPath: clearQueryString(router.asPath),
			});
		}

		if (!themePage || themePage?.status === 'disabled') {
			return <NotMatchPage />;
		}

		const {
			content: { list, key, isPrivatePage },
		} = themePage;
		const isHomePage = key === 'home';
		const showList = _.filter(list, ({ hide }) => !hide);

		if (isHomePage) {
			const redirectUrl = getRedirectUrl(domain);
			if (redirectUrl && !checkIsServer()) {
				window.open(redirectUrl, '_self');
			}
		}
		const needLogin = isPrivatePageNeedLogin({ isPrivatePage, closedWeb });
		return (
			<>
				<RedirectClosedLogin needLogin={needLogin} />
				<div
					className={classNames([
						classStyle.container,
						isHomePage && classStyle.commonMainBackgroundColor,
					])}
				>
					{_.map(showList, this.renderContent)}
				</div>
				<BottomNavigation />
			</>
		);
	}
}

function mapStateToProps(state) {
	const { themes, homePage, pages } = getThemeSelector(state);
	const domain = getDomain(state);

	return {
		themes,
		homePage,
		pages,
		domain,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators({ ...actionCreators }, dispatch),
	};
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(HomePage),
);
