import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import useTranslation from '@src/hooks/useTranslation';
import {
	SwitchSystemIcon,
	HRLogoIcon,
	SwitchHRLogoIconWhite,
	SwitchCourseLogoIconGreen,
	SelectedSystemIcon,
} from '@src/components/common/icon';
import { getUserProfile } from '@src/store/selectors/user';
import { getThemeSelector } from '@src/store/selectors';
import { logoutCall } from '@src/store/actions/session';

import { RowWrapper } from './hr/punchIn/styles';

const SystemContent = styled.div`
	display: flex;
	position: relative;
	.text {
		color: #676767;
		font-size: 12px;
		font-weight: 400;
	}
	:hover {
		background: #F5F5F7;
		border-radius: 100px;
		.switch-system-option {
			display: grid;
		}
		.switch-system-icon {
			path {
				fill: #3091FD;
				stroke: #3091FD;
			}
		}
	}
`;

const SystemOptionWrapper = styled(RowWrapper)`
	padding: 8px 0px;
	gap: 8px;
	justify-content: flex-start;
	cursor: pointer;
	:hover {
		border-radius: 8px;
		background: #F5F5F7;
	}
`;

const StyledSelectedSystemIcon = styled(SelectedSystemIcon)`
`;

export const SystemContentInfo = styled.div`
	position: absolute;
	background: transparent;
	padding-top: 60px;
	left: -5px;
	z-index: 9999999;
	display: none;
`;

const SystemOption = styled.div`
	padding: 20px;
	border-radius: 8px;
	background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.20);
	white-space: nowrap;
	display: grid;
	gap: 12px;
	padding: 12px 40px;
`;

const SystemText = styled.div`
	cursor: pointer;
  margin-right: 8px;
`;

const BleakText = styled.div`
	font-size: 14px;
	cursor: pointer;
	border-radius: 8px;
	padding: 8px;
	:hover {
		background: #F5F5F7;
	}
`;

const AvatarContent = styled.div`
	margin-left: 12px;
	position: relative;
	:hover {
		.avatar-info {
			display: block;
		}
	}
`;

const AvatarIcon = styled.div`
	width: 32px;
	height: 32px;
	border-radius: 32px;
	border: 1px solid #FFF;
	background: #DEDEDE;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: white;
	background: #1150ae;
	cursor: pointer;
`;

const AvatarImg = styled.img`
	width: 32px;
	height: 32px;
	border-radius: 32px;
`;
const InfoContent = styled.div`
	position: absolute;
	background: transparent;
	padding-top: 20px;
	right: 0;
	z-index: 999;
	display: none;
	right: -10px;
`;

const AvatarInfo = styled.div`
	padding: 12px;
	border-radius: 8px;
	background: #FFF;
	width: 240px;
	box-shadow: 0px 0px 12px 0px rgba(69, 65, 78, 0.09);
`;

const BoldText = styled.div`
	font-weight: bold;
	margin-bottom: 12px;
`;

const GreyText = styled.span`
	color: #676767;
	font-size: 14px;
	font-weight: 300;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: #E9E9E9;
	margin: 4px 0;
`;

const ProfileButton = styled.div`
	padding: 8px;
	font-size: 14px;
	margin-bottom: 4px;
	cursor: pointer;
	:hover {
		border-radius: 8px;
		background: #F5F5F7;
	}
`;

const LogOutButton = styled.div`
	padding: 8px;
	font-size: 14px;
	cursor: pointer;
	:hover {
		border-radius: 8px;
		background: #F5F5F7;
	}
`;

const SystemNameWrapper = styled.div`
	color: #222;
	font-size: 18px;
	font-weight: 400;
	height: 27px;
	display: flex;
	margin-left: 12px;
	align-items: center;
	& span {
		margin-left: 4px;
	}
`;

const NameWrapper = styled.div`
	padding: 8px 8px 12px 8px;
`;

const SideMenuHeader = (): JSX.Element => {
	const { t } = useTranslation();
	const router = useRouter();
	const userProfile = useSelector(getUserProfile) as any;
	const { themes } = useSelector(getThemeSelector) as any;
	const dispatch = useDispatch();
	const handleLogout = () => {
		dispatch(logoutCall());
	};
	return (
		<>
			<RowWrapper>
				<SystemContent>
					<SwitchSystemIcon className="switch-system-icon" />
					{
						themes?.can_switch_course_system || themes?.can_switch_hr_system ? (
							<SystemContentInfo className="switch-system-option">
								<SystemOption className="switch-system-option">
									<div className="text">{t('all:switch_system')}</div>
									{
										themes?.can_switch_hr_system && (
											<SystemOptionWrapper onClick={() => router.push('/hr')}>
												<SwitchHRLogoIconWhite />
												<SystemText>
													{t('all:hr_system_name')}
												</SystemText>
												<StyledSelectedSystemIcon />
											</SystemOptionWrapper>
										)
									}
									{
										themes?.can_switch_course_system && (
											<SystemOptionWrapper onClick={() => router.push('/training')}>
												<SwitchCourseLogoIconGreen />
												<SystemText>
													{t('all:course_system_name')}
												</SystemText>
											</SystemOptionWrapper>
										)
									}
								</SystemOption>
							</SystemContentInfo>
						) : null
					}
				</SystemContent>
				<SystemNameWrapper onClick={() => router.push('/hr')}>
					<HRLogoIcon />
					<span>{t('all:hr_system_name')}</span>
				</SystemNameWrapper>
			</RowWrapper>
			<RowWrapper>
				<BleakText
					onClick={() => {
						window.open('https://teaches.cc/admin/login', '_blank');
					}}
				>
					{t('all:go_to_admin_console')}
				</BleakText>
				<AvatarContent>
					{
						userProfile?.profile_picture_link?.link ? (
							<AvatarImg src={userProfile.profile_picture_link.link} />
						) : (
							<AvatarIcon>
								{userProfile?.name?.[0]}
							</AvatarIcon>
						)
					}
					<InfoContent className="avatar-info">
						<AvatarInfo>
							<NameWrapper>
								<BoldText>{userProfile.name}</BoldText>
								<div>
									{
										userProfile?.employee_id && (
											<GreyText>{userProfile?.employee_id}</GreyText>
										)
									}
									{
										userProfile?.employee_title && (
											<>
												<GreyText>｜</GreyText>
												<GreyText>{userProfile?.employee_title}</GreyText>
											</>
										)
									}
								</div>
								<GreyText>
									<span>
										{
											(userProfile?.departments || []).join('、')
										}
									</span>
								</GreyText>
							</NameWrapper>
							<Divider />
							<ProfileButton>
								{t('all:profile')}
							</ProfileButton>
							<LogOutButton onClick={handleLogout}>
								{t('common:logout')}
							</LogOutButton>
						</AvatarInfo>
					</InfoContent>
				</AvatarContent>
			</RowWrapper>
		</>
	);
};

export default SideMenuHeader;
