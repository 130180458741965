import React from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { Radio, Collapse } from 'antd';

import {
	WarningIcon, ModalSuccessIcon, ModalErrorIcon, ModalInfoIcon,
	ArrowBackLeft, ModalCloseIcon,
} from '@src/components/common/icon';
import Button from '@src/components/common/button';

const { Panel } = Collapse;

const GlobalStyle = createGlobalStyle`
	body {
		/* position: fixed;
		overflow: hidden; */
	}
`;

const fadeInAndEnlarge = keyframes`
  from {
    opacity: 0;
		transform: scale(0.9);
  }
  to {
    opacity: 1;
		transform: scale(1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MODAL_HEADER_HEIGHT = 68;
const MODAL_FOOTER_HEIGHT = 67;
const MODAL_FOOTER_HEIGHT_MOBILE = 64;

const ModalWrapper = styled.div.attrs({ className: 'modal-wrapper' })`
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	display: ${props => (props.isActive ? 'flex' : 'none')};
	width: 100vw;
	height: 100vh;
	z-index: 99999;
	justify-content: center;
	align-content: center;
`;

const ModalBackground = styled.div.attrs({ className: 'modal-background' })`
	position: fixed;
	right: -50%;
	top: -50%;
	bottom: -50%;
	left: -50%;
	background-color: rgba(0, 0, 0, 0.5);
	animation: ${fadeIn} 225ms cubic-bezier(0, 0, 0.2, 1) 0ms forwards;
`;

const ModalContent = styled.div.attrs({ className: 'modal-content' })`
	overflow: scroll;
	max-height: 100vh;
	position: relative;
	width: ${props => (props.modalWidth?.desktop ? props.modalWidth.desktop : 912)}px;
	border-radius: 4px;
	margin: auto;
	background: #fff;
	animation: ${fadeInAndEnlarge} 225ms cubic-bezier(0, 0, 0.2, 1) 0ms forwards;

	@media ${props => props.theme.device.tablet} {
		width: ${props => (props.modalWidth?.tablet ? props.modalWidth.tablet : 728)}px;
	}

	@media ${props => props.theme.device.smTablet} {
		width: ${props => (props.modalWidth?.smTablet ? props.modalWidth.smTablet : 496)}px;
	}

	@media ${props => props.theme.device.mobile} {
		position: fixed;
		width: 100vw;
		height: 100%;
		border-radius: unset;
		margin: 0;
		}
`;

const ModalHeader = styled.div`
	/* display: none; */
	position: relative;
	padding: 24px 12px;
	font-weight: 500;
	color: #333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	display: flex;
	border-bottom: 1px solid #E9E9E9;
	/* @media ${props => props.theme.device.mobile} {
		display: flex;
		border-bottom: 1px solid #E9E9E9;
	} */
`;

const ModalStatus = styled.div`
	position: relative;
	padding: 40px 20px 12px;
	font-weight: 500;
	color: #333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const ModalBody = styled.div.attrs({ className: 'modal-body' })`
	// Need to minus margin of modal and height of modal header and footer.
	max-height: ${props => (props.hasFooter ?
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px - ${MODAL_FOOTER_HEIGHT}px)` :
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px)`)};
	padding: 0px 20px;
	overflow-y: auto;
	margin-bottom: 40px;

	@media ${props => props.theme.device.mobile} {
		height: ${(props: { hasFooter: boolean }) => (props.hasFooter ?
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px - ${MODAL_FOOTER_HEIGHT}px)` :
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px)`)};
		max-height: unset;
		padding-bottom: ${(props: { hasFooter: boolean }) => (props.hasFooter ?
		`${MODAL_FOOTER_HEIGHT}px !important` :
		'24px')};
		padding: 24px 16px;
	}
`;

const ModalFooter = styled.div.attrs({ className: 'modal-footer' })`
	height: ${MODAL_FOOTER_HEIGHT}px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 12px 20px;
	border-top: 1px solid #F0F0F0;
	background: #fff;
	@media ${props => props.theme.device.mobile} {
		height: ${MODAL_FOOTER_HEIGHT_MOBILE}px;
		position: fixed;
		bottom: 0;
		width: 100%;
	}
`;

const StyleWarningIcon = styled(WarningIcon)`
	margin-bottom: 16px;
`;

const StyleSuccessIcon = styled(ModalSuccessIcon)`
	margin-bottom: 16px;
`;

const StyleModalErrorIcon = styled(ModalErrorIcon)`
	margin-bottom: 16px;
`;

const StyleModalInfoIcon = styled(ModalInfoIcon)`
	margin-bottom: 16px;
`;

export const StylePanel = styled(Panel)`
	white-space: pre-wrap;
	.ant-collapse-arrow {
		transform: rotate(90deg);
		margin-left: 4px !important;
		margin-right: 0px !important;
		vertical-align: 2px !important;
	}
	&.ant-collapse-item-active {
		.ant-collapse-arrow {
			transform: rotate(180deg);
		}
	}
	.ant-collapse-content-active {
		margin-bottom: 60px;
	}
	.ant-collapse-header {
		justify-content: center;
		align-items: center;
		flex-direction: row-reverse;
	}
`;
export const PanelText = styled.div`
	color: #676767;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const DescText = styled.div`
	color: #676767;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 22px;
	margin-bottom: 12px;
	display: flex;
	justify-content: center;
	text-align: center;
	white-space: pre-wrap;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	width: 250px;
	gap: 12px;
	margin-top: ${(props: { isMobileHide?: boolean }) => (props.isMobileHide ? '12px' : '0')};
	@media ${props => props.theme.device.mobile} {
		width: 100%;
		display: ${(props: { isMobileHide?: boolean }) => (props.isMobileHide ? 'none' : 'flex')};
		margin-top: 0px;
		align-items: center;
		justify-content: center;
	}
`;

export const ActionButton = styled(Button)`
	width: 100%;
	height: 40px;
	padding: 8px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
	display: flex !important;
	justify-content: center;
	padding: 12px 0;
	.ant-space-item {
		font-size: 16px;
		font-weight: 400;
		color: #222;
	}
	.ant-space {
		gap: 20px !important;
	}
`;

const ArrowLeftIconWrapper = styled(ArrowBackLeft)`
	display: none;
	position: absolute;
	left: 24px;
	cursor: pointer;

	@media ${props => props.theme.device.mobile} {
		display: block;
	}
`;

const StyleCloseIcon = styled(ModalCloseIcon)`
	display: block;
	position: absolute;
	right: 24px;
	cursor: pointer;

	@media ${props => props.theme.device.mobile} {
		display: none;
	}
`;

interface IModalWidth {
	desktop?: number;
	tablet?: number;
	smTablet?: number;
}

interface IModal {
	modalWidth?: IModalWidth;
	className?: string;
	isActive: boolean;
	noHeader?: boolean;
	headerText?: string;
	footer?: JSX.Element;
	handleClose?: () => void;
	children: JSX.Element | JSX.Element[];
	disableScroll?: boolean;
	status?: 'warning' | 'success' | 'error' | 'info';
}

// 電腦版可參考 src/components/hr/attendanceHistory/deleteModal.tsx
const Modal = ({
	modalWidth, className, isActive, noHeader,
	headerText, footer, handleClose, children,
	disableScroll = true, status = 'warning',
}: IModal): JSX.Element => {
	const hasFooter = !!footer;
	if (!isActive) return null;
	const isInLine = navigator?.userAgent?.toLowerCase().includes('line');
	return (
		<>
			{disableScroll && <GlobalStyle />}
			<div className={className}>
				<ModalWrapper isActive={isActive}>
					<ModalBackground onClick={handleClose} />
					<ModalContent modalWidth={modalWidth}>
						{!noHeader && !isInLine && (
							<ModalHeader>
								<ArrowLeftIconWrapper onClick={handleClose} />
								<StyleCloseIcon onClick={handleClose} />
							</ModalHeader>
						)}
						<ModalBody id="modal-body" hasFooter={hasFooter}>
							<ModalStatus>
								{ status === 'warning' && <StyleWarningIcon />}
								{ status === 'success' && <StyleSuccessIcon />}
								{ status === 'error' && <StyleModalErrorIcon />}
								{ status === 'info' && <StyleModalInfoIcon />}
								{headerText}
							</ModalStatus>
							{children}
						</ModalBody>
						{hasFooter && (<ModalFooter>{footer}</ModalFooter>)}
					</ModalContent>
				</ModalWrapper>
			</div>
		</>
	);
};

export default Modal;
