/* eslint-disable max-len */
import { createAction } from 'redux-actions';
import {
	GET_SHIFT_CALL,
	GET_SHIFT_DONE,
	GET_SHIFT_FAIL,
	POST_SHIFT_CALL,
	POST_SHIFT_DONE,
	POST_SHIFT_FAIL,
	DELETE_SHIFT_CALL,
	DELETE_SHIFT_DONE,
	DELETE_SHIFT_FAIL,
	GET_SHIFT_DETAIL_CALL,
	GET_SHIFT_DETAIL_DONE,
	GET_SHIFT_DETAIL_FAIL,
	PATCH_SHIFT_CALL,
	PATCH_SHIFT_DONE,
	PATCH_SHIFT_FAIL,
	GET_SHIFT_SCHEDULERS_SETTINGS_CALL,
	GET_SHIFT_SCHEDULERS_SETTINGS_DONE,
	GET_SHIFT_SCHEDULERS_SETTINGS_FAIL,
	POST_SHIFT_SCHEDULERS_SETTINGS_CALL,
	POST_SHIFT_SCHEDULERS_SETTINGS_DONE,
	POST_SHIFT_SCHEDULERS_SETTINGS_FAIL,
	GET_SHIFT_SCHEDULES_CALL,
	GET_SHIFT_SCHEDULES_DONE,
	GET_SHIFT_SCHEDULES_FAIL,
	POST_SHIFT_SCHEDULES_CALL,
	POST_SHIFT_SCHEDULES_DONE,
	POST_SHIFT_SCHEDULES_FAIL,
	DELETE_SHIFT_SCHEDULES_CALL,
	DELETE_SHIFT_SCHEDULES_DONE,
	DELETE_SHIFT_SCHEDULES_FAIL,
	GET_SHIFT_SCHEDULES_DETAIL_CALL,
	GET_SHIFT_SCHEDULES_DETAIL_DONE,
	GET_SHIFT_SCHEDULES_DETAIL_FAIL,
	PATCH_SHIFT_SCHEDULES_CALL,
	PATCH_SHIFT_SCHEDULES_DONE,
	PATCH_SHIFT_SCHEDULES_FAIL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DONE,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_FAIL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_DONE,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_FAIL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_DONE,
	GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_FAIL,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE,
	GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE,
	POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_DONE,
	GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_FAIL,
	POST_SHIFT_SCHEDULES_PUBLISH_CALL,
	POST_SHIFT_SCHEDULES_PUBLISH_DONE,
	POST_SHIFT_SCHEDULES_PUBLISH_FAIL,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_DONE,
	GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_FAIL,
	GET_MEMBERS_CALL,
	GET_MEMBERS_DONE,
	GET_MEMBERS_FAIL,
} from '@src/store/types';

export const getShiftCall = createAction(GET_SHIFT_CALL);
export const getShiftDone = createAction(GET_SHIFT_DONE);
export const getShiftFail = createAction(GET_SHIFT_FAIL);
export const postShiftCall = createAction(POST_SHIFT_CALL);
export const postShiftDone = createAction(POST_SHIFT_DONE);
export const postShiftFail = createAction(POST_SHIFT_FAIL);
export const deleteShiftCall = createAction(DELETE_SHIFT_CALL);
export const deleteShiftDone = createAction(DELETE_SHIFT_DONE);
export const deleteShiftFail = createAction(DELETE_SHIFT_FAIL);
export const getShiftDetailCall = createAction(GET_SHIFT_DETAIL_CALL);
export const getShiftDetailDone = createAction(GET_SHIFT_DETAIL_DONE);
export const getShiftDetailFail = createAction(GET_SHIFT_DETAIL_FAIL);
export const patchShiftCall = createAction(PATCH_SHIFT_CALL);
export const patchShiftDone = createAction(PATCH_SHIFT_DONE);
export const patchShiftFail = createAction(PATCH_SHIFT_FAIL);
export const getShiftSchedulersSettingsCall = createAction(GET_SHIFT_SCHEDULERS_SETTINGS_CALL);
export const getShiftSchedulersSettingsDone = createAction(GET_SHIFT_SCHEDULERS_SETTINGS_DONE);
export const getShiftSchedulersSettingsFail = createAction(GET_SHIFT_SCHEDULERS_SETTINGS_FAIL);
export const postShiftSchedulersSettingsCall = createAction(POST_SHIFT_SCHEDULERS_SETTINGS_CALL);
export const postShiftSchedulersSettingsDone = createAction(POST_SHIFT_SCHEDULERS_SETTINGS_DONE);
export const postShiftSchedulersSettingsFail = createAction(POST_SHIFT_SCHEDULERS_SETTINGS_FAIL);
export const getShiftSchedulesCall = createAction(GET_SHIFT_SCHEDULES_CALL);
export const getShiftSchedulesDone = createAction(GET_SHIFT_SCHEDULES_DONE);
export const getShiftSchedulesFail = createAction(GET_SHIFT_SCHEDULES_FAIL);
export const postShiftSchedulesCall = createAction(POST_SHIFT_SCHEDULES_CALL);
export const postShiftSchedulesDone = createAction(POST_SHIFT_SCHEDULES_DONE);
export const postShiftSchedulesFail = createAction(POST_SHIFT_SCHEDULES_FAIL);
export const deleteShiftSchedulesCall = createAction(DELETE_SHIFT_SCHEDULES_CALL);
export const deleteShiftSchedulesDone = createAction(DELETE_SHIFT_SCHEDULES_DONE);
export const deleteShiftSchedulesFail = createAction(DELETE_SHIFT_SCHEDULES_FAIL);
export const getShiftSchedulesDetailCall = createAction(GET_SHIFT_SCHEDULES_DETAIL_CALL);
export const getShiftSchedulesDetailDone = createAction(GET_SHIFT_SCHEDULES_DETAIL_DONE);
export const getShiftSchedulesDetailFail = createAction(GET_SHIFT_SCHEDULES_DETAIL_FAIL);
export const patchShiftSchedulesCall = createAction(PATCH_SHIFT_SCHEDULES_CALL);
export const patchShiftSchedulesDone = createAction(PATCH_SHIFT_SCHEDULES_DONE);
export const patchShiftSchedulesFail = createAction(PATCH_SHIFT_SCHEDULES_FAIL);
export const postShiftSchedulesAssignmentsCall = createAction(POST_SHIFT_SCHEDULES_ASSIGNMENTS_CALL);
export const postShiftSchedulesAssignmentsDone = createAction(POST_SHIFT_SCHEDULES_ASSIGNMENTS_DONE);
export const postShiftSchedulesAssignmentsFail = createAction(POST_SHIFT_SCHEDULES_ASSIGNMENTS_FAIL);
export const getShiftSchedulesMyShiftsAssignmentsCall = createAction(GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_CALL);
export const getShiftSchedulesMyShiftsAssignmentsDone = createAction(GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_DONE);
export const getShiftSchedulesMyShiftsAssignmentsFail = createAction(GET_SHIFT_SCHEDULES_MY_SHIFTS_ASSIGNMENTS_FAIL);
export const getShiftSchedulesMyShiftsLeaveApplicationsCall = createAction(GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_CALL);
export const getShiftSchedulesMyShiftsLeaveApplicationsDone = createAction(GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_DONE);
export const getShiftSchedulesMyShiftsLeaveApplicationsFail = createAction(GET_SHIFT_SCHEDULES_MY_SHIFTS_LEAVE_APPLICATIONS_FAIL);
export const getShiftSchedulesAssignmentsDraftCall = createAction(GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL);
export const getShiftSchedulesAssignmentsDraftDone = createAction(GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE);
export const getShiftSchedulesAssignmentsDraftFail = createAction(GET_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL);
export const postShiftSchedulesAssignmentsDraftCall = createAction(POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_CALL);
export const postShiftSchedulesAssignmentsDraftDone = createAction(POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_DONE);
export const postShiftSchedulesAssignmentsDraftFail = createAction(POST_SHIFT_SCHEDULES_ASSIGNMENTS_DRAFT_FAIL);
export const getShiftSchedulesLeaveApplicationsCall = createAction(GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_CALL);
export const getShiftSchedulesLeaveApplicationsDone = createAction(GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_DONE);
export const getShiftSchedulesLeaveApplicationsFail = createAction(GET_SHIFT_SCHEDULES_LEAVE_APPLICATIONS_FAIL);
export const postShiftSchedulesPublishCall = createAction(POST_SHIFT_SCHEDULES_PUBLISH_CALL);
export const postShiftSchedulesPublishDone = createAction(POST_SHIFT_SCHEDULES_PUBLISH_DONE);
export const postShiftSchedulesPublishFail = createAction(POST_SHIFT_SCHEDULES_PUBLISH_FAIL);
export const getShiftSchedulesShiftEmployeesCall = createAction(GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_CALL);
export const getShiftSchedulesShiftEmployeesDone = createAction(GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_DONE);
export const getShiftSchedulesShiftEmployeesFail = createAction(GET_SHIFT_SCHEDULES_SHIFT_EMPLOYEES_FAIL);
export const getMembersCall = createAction(GET_MEMBERS_CALL);
export const getMembersDone = createAction(GET_MEMBERS_DONE);
export const getMembersFail = createAction(GET_MEMBERS_FAIL);
