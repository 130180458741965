import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #f5f5f7;
  width: 100%;
  min-height: 100vh;
  .img {
    height: 144px;
    width: 520px;
    margin-bottom: 40px;
  }
  .text {
    text-align: center;
  }

  .title {
    font-size: 18px;
    color: #101010;
    font-weight: 400;
  }
  .sub-title {
    font-size: 16px;
    font-weight: 400;
    color: #101010;
    margin: 12px 0 0;
  }

  @media (max-width: 768px) {
    .img {
      height: 99px;
      width: 359px;
    }
  }
`;
