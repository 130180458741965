import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { INPUT_ONLY_NUMBER_TYPE } from '@src/const/common';

import InputLabel from './inputLabel';
import ErrorMsg from './errorMsg';

const FormItem = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;

	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const Indication = styled.div.attrs({ className: 'indication' })`
	margin-bottom: 12px;
	font-size: 14px;
	font-weight: 300;
	color: #222;
`;

const StyledField = styled(Field).attrs({ className: 'input' })`
	padding: 10px 12px 10px 12px;
	border: 1px solid #DDDDDD;
	border-radius: 4px;
	border-color: ${(props: { $isError: string }) => (props.$isError === 'true' ? '#D0021B' : '#DDDDDD')};
	margin-bottom: ${(props: { $isError: string }) => (props.$isError === 'true' ? '4px' : '0px')};
	font-size: 14px;
	color: #333333;
	outline-color: unset;
	outline: none;

	:focus, :hover {
		border: 1px solid #676767;
	}

	::placeholder {
		font-weight: 300;
		font-size: 14px;
		line-height: 21px;
		color: #9C9C9C;
	}

	:disabled {
		background: #F5F5F7 !important;
		border: 1px solid #DDD !important;
		color: #676767 !important;
	}
`;

const IconWrapper = styled.div`
	position: absolute;
	top: 36px;
	right: 9px;
	cursor: ${(props: { canClick: string }) => (props.canClick === 'true' ? 'pointer' : 'auto')};
`;

export interface IInput {
	className?: string;
	id: string;
	label?: string;
	indication?: string | JSX.Element;
	placeholder?: string;
	isError?: boolean;
	errorMsg?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	component?: string;
	rows?: number;
	maxLength?: number;
	type?: string;
	onKeyUp?: () => void;
	autoFocus?: boolean;
	icon?: JSX.Element
	iconClick?: () => void;
}

const onlyNumberKeyPress = event => {
	const keyCode = event.keyCode || event.which;
	const keyValue = String.fromCharCode(keyCode);

	if (!/[0-9]/.test(keyValue)) event.preventDefault();
};

const Input = ({
	className, id, label, indication, placeholder, isError = false, errorMsg,
	isRequired, component = 'input', rows, maxLength, type = 'text', isDisabled = false,
	onKeyUp, autoFocus, icon, iconClick,
}: IInput): JSX.Element => {
	const inputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		if (inputRef && inputRef.current && autoFocus) {
			inputRef.current.focus();
		}
	}, []);
	return (
		<FormItem className={className}>
			{label && (<InputLabel htmlFor={id} isRequired={isRequired}>{label}</InputLabel>)}
			{indication && (<Indication>{indication}</Indication>)}
			<StyledField
				id={id}
				name={id}
				type={type === INPUT_ONLY_NUMBER_TYPE ? 'number' : type}
				placeholder={placeholder}
				$isError={isError.toString()}
				component={component}
				maxLength={maxLength}
				disabled={isDisabled}
				onKeyUp={onKeyUp}
				onKeyPress={event => {
					if (type === INPUT_ONLY_NUMBER_TYPE) onlyNumberKeyPress(event);
				}}
				innerRef={inputRef}
				{...component === 'textarea' ? { rows } : {}}
			/>
			{
				icon && (
					<IconWrapper canClick={(!!iconClick).toString()} onClick={iconClick}>
						{icon}
					</IconWrapper>
				)
			}
			<ErrorMsg className="errorMsg" isError={isError}>{errorMsg}</ErrorMsg>
		</FormItem>
	);
};

export default Input;
