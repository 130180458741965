import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

import useMediaQuery from '@src/hooks/useMediaQuery';
import Menu from '@src/components/menu';

import SideMenuHeader from '@src/components/SideMenuHeader';
import SideMenuFooter from '@src/components/SideMenuFooter';

import { CollapsedIcon } from '@src/components/common/icon';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

const TopBar = styled.div`
	background-color: #fff;
	color: #333;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
	height: 52px;
	border-bottom: 1px solid #E9E9E9;
	@media ${props => props.theme.device.mobile} {
		display: none;
	}
`;

const SideMenu = styled(motion.div)`
	position: relative;
	background-color: #fff;
	width: 230px;
	border-right: 1px solid #E9E9E9;
	@media
		${props => props.theme.device.smTablet},
		${props => props.theme.device.tablet} {
		position: fixed;
		height: 100vh;
		z-index: 99999;
	}
	@media ${props => props.theme.device.mobile} {
		display: none !important;
		width: 0px;
	}
`;

const MainContent = styled.div`
	flex-grow: 1;
	background-color: #F5F5F7;
	overflow-y: auto;
	width: calc(100vw - ${props => props.sideMenuWidth}px);
	height: 100%;
	max-height: calc(100vh - 100px);
	@media ${props => props.theme.device.mobile} {
		width: 100vw;
		max-height: unset;
	}
	@media
		${props => props.theme.device.smTablet},
		${props => props.theme.device.tablet} {
		margin-left: 20px;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-grow: 1;
	height: calc(100vh - 100px);
	position: relative;
`;

const Footer = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	width: calc(100vw - ${props => props.sideMenuWidth}px);
	display: flex;
	justify-content: center;
	align-items: center;
	height: 53px;
	border-top: 1px solid #E9E9E9;
	background: #FFF;
	color: #333;
	@media ${props => props.theme.device.mobile} {
		display: none;
	}
	@media
		${props => props.theme.device.smTablet},
		${props => props.theme.device.tablet} {
		margin-left: 20px;
		width: calc(100vw - 20px);
	}
`;

const CollapsedWrapper = styled.div`
	position: absolute;
	right: -14px;
	top: 12px;
	border-radius: 100px;
	border: 1px solid #E9E9E9;
	background: #FFF;
	width: 28px;
	height: 28px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	cursor: pointer;
	transform: rotate(0deg);
	${props => props.collapsed && `
		transform: rotate(180deg);
	`}

	:hover {
		border-radius: 100px;
		border: 1px solid #E9E9E9;
		background: #3091FD;
		& svg path {
			stroke: #FFF;
		}
	}
	@media ${props => props.theme.device.desktop},
		${props => props.theme.device.lgDesktop},
		${props => props.theme.device.mobile} {
		display: none;
	}
`;

const OpenSlideMask = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.50);
	z-index: 999;
	@media ${props => props.theme.device.mobile} {
		display: none;
	}
`;

const SideMenuWrapper = ({
	children,
}: {
	children: React.ReactNode;
}): JSX.Element => {
	const { mediaMatch: { isDesktop, isLgDesktop } } = useMediaQuery();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [collapsed, setCollapsed] = useState(false);
	let sideMenuWidth = 230;
	const isOpenMenu = collapsed;
	// const isOpenMenu = collapsed;
	if (collapsed === false) sideMenuWidth = 20;
	useEffect(() => {
		if (isDesktop || isLgDesktop) {
			setCollapsed(true);
		}
	}, [isDesktop, isLgDesktop]);
	return (
		<Wrapper>
			<TopBar>
				<SideMenuHeader />
			</TopBar>
			<ContentWrapper>
				{collapsed && !(isDesktop || isLgDesktop) && <OpenSlideMask onClick={() => setCollapsed(false)} />}
				<SideMenu
					sideMenuWidth={sideMenuWidth}
					isOpenMenu={isOpenMenu}
					variants={{
						open: { x: 0 },
						closed: { x: '-90%' },
					}}
					animate={isOpenMenu ? 'open' : 'closed'}
					transition={{ ease: 'easeOut' }}
				>
					{
						collapsed && <Menu collapsed={isOpenMenu} />
					}
					<CollapsedWrapper
						collapsed={collapsed}
						onClick={() => setCollapsed(!collapsed)}
					>
						<CollapsedIcon />
					</CollapsedWrapper>
				</SideMenu>
				<MainContent sideMenuWidth={sideMenuWidth} className="hr-main-content">
					{children}
				</MainContent>
				<Footer sideMenuWidth={sideMenuWidth}>
					<SideMenuFooter />
				</Footer>
			</ContentWrapper>
		</Wrapper>
	);
};

export default SideMenuWrapper;
