export const GET_DEPARTMENTS_CALL = 'GET_DEPARTMENTS_CALL';
export const GET_DEPARTMENTS_DONE = 'GET_DEPARTMENTS_DONE';
export const GET_DEPARTMENTS_FAIL = 'GET_DEPARTMENTS_FAIL';

export const GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL = 'GET_DEPARTMENTS_OWNED_DEPARTMENT_CALL';
export const GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE = 'GET_DEPARTMENTS_OWNED_DEPARTMENT_DONE';
export const GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL = 'GET_DEPARTMENTS_OWNED_DEPARTMENT_FAIL';

export const GET_DEPARTMENTS_LEARNING_RECORDS_CALL = 'GET_DEPARTMENTS_LEARNING_RECORDS_CALL';
export const GET_DEPARTMENTS_LEARNING_RECORDS_DONE = 'GET_DEPARTMENTS_LEARNING_RECORDS_DONE';
export const GET_DEPARTMENTS_LEARNING_RECORDS_FAIL = 'GET_DEPARTMENTS_LEARNING_RECORDS_FAIL';

export const GET_DEPARTMENTS_EMPLOYEES_CALL = 'GET_DEPARTMENTS_EMPLOYEES_CALL';
export const GET_DEPARTMENTS_EMPLOYEES_DONE = 'GET_DEPARTMENTS_EMPLOYEES_DONE';
export const GET_DEPARTMENTS_EMPLOYEES_FAIL = 'GET_DEPARTMENTS_EMPLOYEES_FAIL';
